.signin-block__field--first-name {
  display: none;
}

.signin-block__field--last-name {
  display: none;
}

.signin-block__authed__nav {
  width: auto;
}

@media (min-width: 1200px) {
  #signin {
    .sign-in-page {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#password_request,
#password_sent,
#password_reset {
  #customer-service {
    display: none;
  }
}

#landing {
  .account-page {
    .section-content {
      span {
        font-size: 18px;
        letter-spacing: 0.06em;
      }
    }
  }
  .account-address {
    .section-content {
      margin-bottom: 20px;
    }
  }
}

#profile_preferences {
  .profile-page__content {
    .account-section__header {
      font-size: 1.5em;
      letter-spacing: 0.1em;
      font-weight: 700;
    }
    .optional-info__item--header {
      font-size: 1.3em;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    .newsletter-info__header {
      font-size: 1.3em;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    .newsletter_form_container {
      margin-bottom: 20px;
      #pc-promotions {
        padding: 1.5em 0;
        .label {
          width: 90%;
        }
      }
      #mobile-promotions {
        padding: 1.5em 0;
        .label {
          width: 90%;
        }
      }
    }
    .reg-youraddress {
      .your-address-input {
        margin-left: 315px;
      }
    }
  }
}

#address_book {
  .address-book-page__content {
    .address-book {
      section.address-book__default {
        width: 50%;
        float: left;
        .address-item {
          width: 78%;
        }
      }
      h5.address-book__header {
        margin: 10px 0;
        letter-spacing: -2px;
      }
    }
  }
}

.address-book-page__overlay {
  .address-form__item {
    .address_lookup_submit {
      input.btn {
        background: $color-off-black;
      }
      input.btn:hover {
        background: $white;
        color: $color-off-black;
      }
    }
    &.help_container {
      .lookup_submit_note {
        display: inline-block;
        margin: 0.5em 0 1.5em;
      }
    }
  }
  .phones_option {
    .radio.field {
      float: left;
      margin-right: 20px;
    }
  }
  .address-overlay {
    .city-state {
      div.select-box {
        width: 100%;
      }
    }
  }
  #cboxClose {
    right: 20px;
    top: 20px;
  }
}

#order_detail {
  .order-details-page__content {
    .order-products {
      .cart-item__products {
        width: 40%;
      }
      .cart-item__price {
        width: 20%;
      }
      .cart-item__qty {
        width: 20%;
      }
      .cart-item__total {
        width: 20%;
      }
    }
    .order-total {
      table {
        tr {
          th {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.favorites-page__content {
  .favorite__list-actions {
    height: auto;
  }
  a.js-add-to-cart {
    float: left;
    font-size: 13px;
  }
}

@media print {
  #favorites {
    .site-header,
    .account-section__sidebar,
    .favorite__list-actions,
    .favorite-sign-in,
    .site-footer {
      display: none;
    }
  }
}

.order-details-page {
  .order-total {
    tr.total_section {
      border-top: 1px solid #a39e9d;
    }
  }
  .cart-item {
    &__monogram {
      .cart-item {
        &__monogram-text {
          img {
            width: 30px;
          }
        }
      }
    }
  }
}
