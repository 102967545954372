$border-grey: #ccc;
$border-dark-grey: #a9a9a9;

#viewcart,
#samples {
  .product_name,
  .product-name {
    display: none;
    a {
      display: none;
    }
  }
}

.viewcart {
  .panel {
    margin: 15px 0 35px;
    header {
      padding: 20px 0 4px;
      position: relative;
      margin: 0.5em 0;
      width: 100%;
      font-weight: bold;
      h2 {
        display: inline-block;
        margin: 0 0 0.5em 0;
        padding: 0;
        text-transform: uppercase;
        font-size: 27px;
      }
    }
  }
  .viewcart-panel {
    border-top: none;
    .viewcart-header {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 30px;
    }
    .viewcart-panel__content {
      .signin-to-see-cart a {
        text-decoration: underline;
      }
    }
  }
  .engraving-wrapper {
    .engraving-view-title {
      margin: 10px;
    }
    .engraving-view-control {
      margin-top: 13px;
      float: left;
    }
  }
}

.viewcart-buttons-panel {
  margin: 15px 0 56px;
  padding: 0;
  text-align: center;
  .go-shopping {
    background: $white;
    color: $color-off-black;
    border: 1px solid $color-off-black;
    padding: 9px 19px 8px 19px;
    &:hover {
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
}

#bottom-viewcart-buttons {
  .go-shopping {
    display: none;
  }
}

.viewcart {
  .column.left.checkout__content {
    border-top: none;
    .viewcart-header {
      h2 {
        font-size: 38px;
        text-align: left;
        width: 90%;
        float: left;
      }
      .item-count {
        display: inline-block;
        width: 10%;
      }
    }
    .panel {
      header {
        border-top: 1px solid $light-gray;
      }
    }
  }
}

.promo-panel {
  .single-message {
    color: red;
  }
}

.messages {
  background-color: transparent;
  color: red;
  margin: 10px 0;
  float: left;
  width: 100%;
  border-bottom: none;
  padding: 0px;
}

.viewcart-buttons-panel {
  .continue-buttons {
    .continue-shopping {
      font-weight: normal;
      margin: 10px 0 0;
      letter-spacing: 1px;
      display: inline-block;
      float: left;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  .continue-checkout-btns {
    float: right;
    margin: 10px 0 0;
    .continue-checkout {
      letter-spacing: 1px;
      text-decoration: none;
      background: #392720;
      color: #fbf8f6;
      padding: 9px 19px 8px 19px;
    }
  }
}

#viewcart .cart-items.products {
  .cart-header {
    .products,
    .column {
      color: $gray;
      float: left;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      padding-bottom: 9px;
    }
    .products {
      width: 55%;
    }
    .price {
      width: 15%;
    }
    .qty {
      width: 13%;
    }
    .total {
      width: 17%;
      text-align: center;
    }
  }
  .cart-item {
    border-bottom: none;
    &.product {
      margin: 0;
      padding: 25px 0 35px 45px;
      border-top: 1px solid $light-gray;
      position: relative;
      min-height: auto;
    }
    &__thumb {
      margin-right: 0;
      width: 15%;
      float: left;
      padding-left: 0;
      margin-top: 7px;
    }
    &__desc {
      padding-right: 2%;
      width: 35%;
      padding-bottom: 30px;
      word-wrap: break-word;
      line-height: 21px;
      float: left;
      padding-left: 0;
      margin-top: 7px;
      min-height: 100px;
    }
    &__price {
      width: 18%;
      float: left;
      padding-left: 0;
      margin-top: 7px;
    }
    &__qty {
      width: 13%;
      float: left;
      padding-left: 0;
      margin-top: 7px;
      .select-box {
        &__label,
        &__options {
          min-width: 77px;
        }
      }
      &__engraving-max {
        width: 13%;
        float: left;
        padding-left: 0;
        margin-top: 7px;
        .select-box {
          &__label,
          &__options {
            min-width: 77px;
            border: 1px solid $red;
          }
        }
      }
    }
    &__total {
      padding-left: 52px;
      width: 19%;
      float: left;
      margin-top: 7px;
    }
    &__replenishment {
      width: 50%;
      margin-top: 30px;
      float: left;
      padding-left: 0;
    }
    &__remove-form {
      a.remove_link {
        bottom: 60px;
        top: inherit;
        right: inherit;
        line-height: normal;
        letter-spacing: 0.05em;
        text-decoration: underline;
        position: inherit;
      }
    }
    &-vskus + .cart-item__remove-form {
      a.remove_link {
        bottom: 45px;
      }
    }
    .price-label,
    .qty-label {
      display: none;
    }
  }
}

.order-summary-panel {
  margin-bottom: 0px !important;
  header {
    font-size: 18px;
    margin-bottom: 0;
  }
  .label {
    text-transform: uppercase;
    width: 85%;
    float: left;
    margin-bottom: 1.5em;
  }
  .value {
    color: $gray;
    width: 15%;
    float: right;
    font-weight: normal;
    text-align: left;
  }
  .total {
    color: $gray;
    border-top: 1px solid $light-gray;
    font-weight: bold;
    margin-bottom: 0;
    padding: 1em 0;
    &.value {
      color: $color-off-black;
    }
  }
}

.checkout__sidebar {
  .order-summary-panel {
    .discount {
      &.label {
        width: 81%;
      }
      &.value {
        width: 19%;
      }
    }
  }
  .tab-pane.active {
    #samples-panel {
      footer {
        display: none;
      }
    }
  }
}

.offer-code-panel {
  form#offer_code {
    margin-bottom: 20px;
  }
  em {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      color: $gray;
    }
  }
}

.delivery_options__content {
  .select-box {
    width: 100%;
  }
}

.need-help-panel.panel {
  border-top: none;
  a.text_link.email {
    padding: 9px 19px 8px 19px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    min-width: 116px;
    max-width: 100%;
    font-size: 15px;
    line-height: 16px;
    background: $white;
    color: $color-off-black;
    border: 1px solid $color-off-black;
    width: 100%;
    &:hover {
      cursor: pointer;
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
}

.tab-switcher {
  ul.tab-bar {
    margin: 25px 0 0 0;
    li {
      float: left;
      width: calc(98.5% / 2);
      margin-right: 0.1em !important;
      border-bottom: solid 1px $color-off-black;
      a {
        border-width: 0px;
        display: block;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        background-color: $white;
        color: $color-off-black !important;
        border: solid 1px $color-off-black;
        border-bottom: 0px !important;
        text-align: center;
        padding: 7px 0px;
        &.active {
          border-width: 0px;
          font-weight: bold;
          background: $color-off-black;
          color: $white !important;
        }
      }
    }
  }
  .tab-content {
    clear: both;
    border-bottom: 1px solid $light-gray;
    .tab-pane {
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 1s ease;
      &.active {
        display: block;
        opacity: 1;
        height: auto;
        overflow: hidden;
      }
      .panel {
        border-top: 0px;
        border-bottom: 0px !important;
        background: $color-light-linen;
        margin-top: 0px;
        .product-img {
          float: left;
          width: 32%;
        }
        .details {
          float: right;
          width: 65%;
          margin-left: 0px !important;
        }
      }
    }
  }
  .remaining {
    .samples-panel {
      &__heading,
      &__promo-text {
        display: none;
        &.large {
          display: inline-block;
        }
      }
    }
  }
}

#samples-panel {
  .samples-panel__promo-text {
    display: none;
    &.large {
      display: inline-block;
    }
  }
}

.links-panel {
  ul.links_list {
    li.link {
      font-size: 14px;
      margin: 0 0 5px 0;
      letter-spacing: 0.08em;
      a {
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

#viewcart {
  #main.single {
    #samples-pane {
      margin-top: 0px;
      .samples-panel {
        .add-button {
          display: none;
        }
        .samples {
          padding-top: 0;
        }
        .product-list {
          li.product {
            margin: 0;
            border-bottom: 1px solid $light-gray;
            height: auto;
            border-spacing: 0.4em;
            position: relative;
            &:first-child {
              border-top: none;
            }
          }
        }
      }
      .product-img {
        img {
          width: 87px;
          height: 147px;
          min-height: 147px;
        }
        .view-larger {
          display: none;
        }
      }
      .details {
        input {
          padding-top: 0;
          height: auto;
          &.sample-select {
            float: left;
            margin-right: 0.3em;
          }
        }
      }
      ul.skus {
        margin: 5px 0px 20px 0px;
        li {
          margin-bottom: 0.5em;
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .continue-with-no-sample-btn {
        display: none;
      }
    }
    .tab-pane {
      header {
        display: none;
      }
      .content {
        padding: 1em;
      }
    }
    #recommended-products-panel {
      .recommended-item {
        min-height: 450px;
        position: relative;
        .info {
          width: 171px;
        }
        .addtobag {
          bottom: 0px;
          position: absolute;
        }
      }
    }
  }
}

.samples-panel {
  a.sample-select-button {
    background: transparent;
    border: 1px solid $color-off-black;
    color: $color-off-black;
    letter-spacing: 0.04em;
    padding: 0 15px;
    height: 36px;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    min-width: 116px;
    max-width: 100%;
    font-size: 15px;
    line-height: 36px;
    cursor: pointer;
  }
}

.pg_wrapper.samples-page {
  position: relative;
  margin: 0 auto 40px;
  max-width: 1024px;
  z-index: 1;
  font-size: 14px;
  margin-top: 124px;
  .samples-buttons.top {
    padding-top: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    float: right;
  }
  .return-link {
    text-align: left;
    padding-top: 5px;
    float: left;
    width: auto;
  }
  .add-button {
    float: left;
    padding-left: 25px;
    .form-submit {
      top: 0;
    }
  }
  ul.product-list {
    padding-top: 20px;
    margin-top: 0;
    overflow: hidden;
    li.product {
      min-height: 470px;
      margin: 0 0 30px 2.2%;
      float: left;
      width: 22.6%;
      padding: 18px 0 10px 0;
      position: relative;
      &.first {
        margin-left: 0;
      }
      .product-img {
        padding-right: 0;
        float: none;
        margin-bottom: 20px;
        .view-larger {
          display: none;
        }
      }
      .details {
        float: none;
        width: 100%;
        text-align: left;
        line-height: 1.4;
        ul.skus {
          bottom: 0;
          position: absolute;
        }
      }
    }
  }
  .samples-buttons.bottom {
    float: right;
    padding-top: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    .continue-with-no-sample-btn {
      float: left;
      margin-left: 10px;
      padding: 9px 19px 8px 19px;
      background: $color-off-black;
      color: $white;
    }
  }
  .viewcart-buttons-panel {
    display: none;
  }
}

.samples-overlay {
  .samples-panel {
    .content {
      height: auto;
      padding: 1em;
      .add-button {
        display: inline-block;
        padding-top: 20px;
        padding-right: 25px;
        float: right;
      }
      .samples {
        float: left;
        width: 100%;
      }
      ul.product-list {
        overflow: hidden;
        max-width: 770px;
        & > li {
          float: left;
          border-bottom: 1px dotted #cccccc;
          padding: 20px 0px;
          position: relative;
          margin: 1em 0;
          width: 770px;
          .product-img {
            width: 85px;
            float: left;
            max-width: 108px;
            text-align: center;
            img {
              width: 87px;
              height: 147px;
            }
            a {
              clear: left;
            }
          }
          &.deactivate {
            color: #808080;
            .product-img {
              img {
                opacity: 0.3;
              }
            }
            .product-name,
            .sample-select-button {
              color: #cccccc !important;
              cursor: default !important;
              border-color: #cccccc !important;
            }
          }
        }
        .details {
          float: left;
          margin-left: 2em;
        }
        .skus {
        }
      }
      .prev-next {
        display: none;
      }
      .view-larger {
        display: none;
      }
    }
  }
}

#mobile-breadcrumb {
  display: none;
}

.delivery_options__content {
  select {
    padding: 0;
    width: 100%;
    margin: 0 0 10px 0;
  }
}

body#index {
  .column.left.checkout__content {
    width: 65%;
    border-top: none;
    .panel {
      > header {
        background: $color-light-linen;
        padding: 0 10px;
        cursor: pointer;
        display: block;
        width: 100%;
        font-weight: bold;
        clear: both;
        border-top: 1px solid $light-gray;
        margin-bottom: 15px;
        a.edit.btn-mini {
          float: none;
          margin: 15px 0 0 15px;
          position: relative;
          bottom: 4px;
          border-bottom: 1px solid $color-off-black;
        }
      }
      &.active {
        > header {
          background: $color-light-linen;
          border-top: none;
        }
      }
      &.finished {
        > header {
          background: none;
        }
      }
      &.collapsed {
        > header {
          border-top: none;
        }
      }
    }
    .content {
      padding: 0 10px 20px 10px;
    }
    input[type='checkbox'] {
      float: left;
      margin: 2px 0;
      width: auto;
      & ~ label {
        margin-left: 10px;
        width: 90%;
        clear: none;
        display: inline-block;
      }
    }
  }
  .sign-in-panel {
    input {
      width: 100%;
    }
    .column {
      width: 50%;
      float: left;
      padding: 1em;
    }
    .content {
      padding: 0 10px 20px 10px;
    }
  }
  #viewcart-panel {
    .viewcart-header {
      h2 {
        width: 80%;
      }
      .item-count {
        width: 20%;
      }
    }
    .cart-items.products {
      .cart-header {
        display: none;
      }
      .cart-item {
        clear: both;
        &:last-child {
          border-bottom: none;
        }
      }
      .column {
        padding: 0.8em 0.6em;
      }
      .thumb {
        width: 20%;
        float: left;
      }
      .desc {
        width: 35%;
        float: left;
        .product_name {
          display: none;
        }
      }
      .qty {
        width: 22%;
        float: left;
      }
      .total {
        width: 23%;
        float: right;
      }
      .price {
        display: none;
      }
    }
  }
  .panel {
    &.collapsed {
      clear: both;
    }
    &.sign-in-panel.finished {
      header {
        background: none;
      }
    }
    .shipping-address,
    .billing-address,
    .billing-address-form {
      .form-item {
        margin: 0px 10px 0px 0;
        display: inline-block;
        width: 48%;
      }
    }
    .continue-button-wrapper {
      text-align: center;
      padding: 0;
      float: right;
      width: auto;
      margin: 20px 0;
      input {
        letter-spacing: 1px;
        background: $color-off-black;
        color: $white;
      }
      &.rakuten {
        input {
          width: 300px;
          background: no-repeat center/100% url('/media/export/cms/checkout/rpay_btn.png');
          color: transparent;
        }
      }
    }
  }
  section.shipping-panel {
    .sub-section {
      .shipping-address,
      .billing-address,
      .billing-address-form {
        div.form_element {
          margin: 0px 10px 0px 0;
          height: auto !important;
          width: 48%;
          display: inline-block;
        }
        fieldset.address.fs {
          input {
            width: 100%;
          }
          .postal_code_container {
            position: relative;
            width: 100%;
            input {
              width: 48%;
            }
            label {
              margin-bottom: 0.5em;
            }
            .fieldset-note {
              top: 0px;
              left: 324px;
              position: absolute;
              a {
                line-height: 22px;
                font-size: 12px;
                padding: 8px 12px;
                width: 220px;
                height: 40px;
                background: $color-off-black;
                color: $white;
              }
              a:hover {
                background: $white;
                color: $color-off-black;
              }
            }
            .lookup_submit_note {
              display: inline-block;
              margin-bottom: 1em;
            }
          }
          .form_element.state_container {
            .select-box {
              width: 100%;
              &__label {
                padding-top: 5px;
                height: 44px;
                &:after {
                  top: 10px;
                }
              }
              &__options {
                top: 43px;
                border-right: 2px solid $color-off-black;
              }
            }
          }
        }
        input[type='radio'] + label + span {
          display: block;
          margin: 15px 0;
        }
        .billing-address-from-fields {
          margin: 15px 0 0 0;
        }
      }
      .shipping-address-display,
      .billing-address-display {
        padding-bottom: 20px;
      }
      .edit-shipping-radio {
        margin-bottom: 20px;
        span {
          display: inline-block;
          width: 45%;
          label {
            display: inline;
            margin-left: 1em;
          }
        }
      }
      .edit-billing-radio {
        label {
          display: inline;
          margin-left: 1em;
        }
      }
    }
    .content.column {
      float: left;
      width: 45%;
      padding: 0 10px 20px 10px;
      h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  section.registration-panel {
    .content {
      .form-item {
        &.email-address {
          margin-bottom: 10px;
        }
        &.password {
          label {
            display: block;
          }
          .password {
            width: 48%;
            display: inline-block;
            margin-right: 12px;
          }
          .text {
            width: 48%;
            display: inline-block;
          }
          p.note {
            margin-top: 10px;
          }
        }
        &.password-hint {
          .select-box {
            width: 100%;
          }
        }
      }
    }
  }
  section.payment-panel {
    .payment-edit-content {
      padding: 0 10px 20px 10px;
      .payment-container {
        margin-bottom: 10px;
        float: left;
        width: 100%;
        input {
          float: left;
          margin-right: 5px;
        }
        label {
          width: 46%;
          float: left;
        }
        &__option--rakuten {
          margin-top: 10px;
          & ~ label {
            width: 95%;
            margin-top: 10px;
          }
        }
      }
      .payment_gmo {
        .payment-form {
          clear: both;
          .form-item {
            &.card-number {
              width: 65%;
              margin: 20px 15px 20px 0;
              padding: 0;
            }
            &.expiry {
              margin-bottom: 1.5em;
              label {
                display: block;
              }
              .expires-month-wrapper,
              .expires-year-wrapper {
                div {
                  display: none;
                }
                select {
                  display: inline-block;
                  width: 48% !important;
                  border: 1px solid #a6a6a6;
                  height: 36px;
                }
              }
              .select-box {
                width: 48% !important;
              }
            }
          }
        }
      }
    }
  }
}

#index {
  fieldset {
    @include clearfix;
    border: 0;
    margin: 0;
    padding: 0;
    clear: both;
    width: 100%;
    max-width: 664px;
    margin-left: 0;
  }
  .form-item {
    input[type='text'],
    input[type='email'],
    input[type='url'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='search'],
    input[type='date'],
    input[type='time'],
    select,
    textarea {
      width: 100% !important;
      background: $white;
    }
    .button {
      margin-left: 1px;
      width: 100%;
      cursor: pointer;
      label {
        display: block;
        cursor: pointer;
      }
    }
    .select-box__label {
      height: 35px;
      line-height: 35px;
    }
  }
  .engraving_option {
    display: none;
  }
  .engraving-wrapper {
    width: 100%;
    padding: 0;
    .engraving-view {
      min-height: 120px;
      border-bottom: 2px solid $border-dark-grey;
    }
    .engraving-view-control,
    .engraving-view-footer {
      display: none;
    }
    .engraving-view-title-top {
      margin-left: 12px;
    }
    .engraving-view-message-content {
      .monogram_custom_text {
        padding: 0 10%;
      }
    }
    .engraving-view-message {
      width: 85%;
      margin-bottom: 10px;
      pointer-events: none;
    }
  }
}

#confirmation-page {
  .column.left.checkout__content {
    border-top: none;
    header {
      padding: 0px 0 18px 0;
      h2 {
        font-size: 41px;
        letter-spacing: -2px;
        text-transform: uppercase;
      }
      .birthday-panel {
        &__title {
          font-size: 20px;
        }
      }
    }
    .content {
      padding: 0 10px 0 10px;
      border-top: 1px solid $light-gray;
      p:nth-child(3) {
        font-size: 19px;
        a {
          font-weight: bold;
        }
      }
    }
    .confirmation-panel__actions {
      margin-left: 0;
      float: left;
      width: 100%;
      padding: 0 10px 0 10px;
      .print-buttons {
        float: left;
        .btn {
          background-color: $color-off-black;
          color: $white;
          width: auto;
          padding: 9px 19px 8px 19px;
          text-transform: uppercase;
        }
      }
      .return-link-container {
        float: right;
        .button {
          background-color: $color-off-black !important;
          border: none;
          border-radius: initial;
          padding: 9px 19px 8px 19px !important;
          line-height: 19px !important;
          height: auto;
          &:hover {
            color: #fbf8f6 !important;
          }
        }
      }
    }
  }
  .column {
    &.right.checkout__sidebar {
      margin: 7% 0 0 6%;
    }
  }
  .checkout-panel__content {
    &.checkout-panel__salon--header {
      padding: 0;
      margin: 0;
      float: left;
      width: 100%;
      h3 {
        border-top: 1px solid $light-gray;
        font-weight: bold;
        padding: 15px 0;
        text-transform: uppercase;
      }
    }
    &.checkout-panel__salon {
      float: left;
      width: 33.3%;
      h3 {
        font-weight: bold;
      }
      span {
        display: block;
      }
    }
  }
}

.column {
  &.right.checkout__sidebar {
    section.panel {
      border-bottom: 1px solid $light-gray;
      padding-bottom: 20px;
      header {
        padding-top: 5px;
      }
    }
  }
}

.checkout__panel-title,
.checkout__subtitle {
  font-size: 28px;
  line-height: 30px;
  margin: 10px 0;
  letter-spacing: -1px;
  font-weight: normal;
  display: inline-block;
  padding: 0;
  text-transform: uppercase;
}

.checkout-esalon {
  line-height: 2;
  h3 {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -1px;
    text-transform: uppercase;
    margin: 15px 0;
  }
  .select-box__label {
    max-width: 664px;
  }
  .esalon__salon-finder-link {
    text-decoration: underline;
  }
}

.viewcart {
  .order-summary__content {
    .cod_fee {
      float: left;
    }
  }
}

.engraving_option {
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  background-color: $color-light-linen;
  .engraving-cart-control {
    margin: 10px;
  }
  .engraving-button-wrapper {
    float: right;
    padding: 10px 0px 0 10px;
  }
  .engraving-show-toggle,
  .engraving-toggle-cancel {
    padding: 0 6px;
    width: 150px;
    font-size: 11px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    border: solid 2px $color-off-black;
    letter-spacing: 0;
  }
  .engraving-toggle-cancel {
    background-color: $color-light-linen;
    color: $color-off-black;
  }
  .engraving-show-toggle {
    background-color: $color-off-black;
    color: $color-light-linen;
  }
}

.engraving-wrapper {
  background-color: $color-light-linen;
  padding: 1em;
  overflow: hidden;
  .engraving-form-element-content,
  .engraving-service-text {
    margin: 20px 10px 10px;
    .description {
      margin: 0 10% 0 10%;
      float: left;
      color: $white;
      text-align: left;
    }
  }
  .monogram-error {
    color: $red;
    margin: 0 0 10px 20px;
  }
  .monogram_custom_text {
    input {
      &.engraving-message {
        width: 100%;
      }
    }
    label {
      margin-bottom: 5px;
    }
  }
  .monogram_custom_image_right {
    margin-left: 2%;
    width: 35%;
    height: 35px;
    .select-box {
      &__label {
        height: 35px;
      }
    }
    &.error {
      .select-box {
        &__label {
          border: 1px solid $red;
          color: $red;
        }
      }
    }
  }
  .engraving-label {
    &.engraving-selected {
      display: block;
      text-align: center;
    }
  }
  .engraving-message-count {
    display: block;
    clear: both;
    margin-bottom: 20px;
  }
  .engraving-message {
    width: 50%;
  }
  select {
    &.monogram_custom_image_right {
      margin-left: 2%;
      width: 35%;
    }
  }
  .action-buttons-div {
    width: 100%;
    height: 40px;
    margin: 10px 0 20px 0;
    clear: both;
  }
  .engraving-save {
    &.action-anchor {
      display: block;
      color: $color-off-black;
      border: 2px solid $color-off-black;
      float: none;
      padding: 12px;
      margin: auto;
      text-decoration: none;
      text-align: center;
      width: 30%;
    }
  }
  div.engraving-view {
    border-top: 2px solid $border-dark-grey;
    padding-top: 1em;
  }
  .engraving-form-element-footer {
    .monogram_left_image {
      width: 48%;
      margin-left: 5%;
    }
    .monogram_right_image {
      width: 38%;
      margin-left: 3%;
    }
  }
  .engraving-view-title {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
  }
  .engraving-view-message {
    text-align: center;
    border: 1px solid $border-grey;
    cursor: pointer;
    font-size: 26px;
    height: 65px;
    background: $white;
    clear: both;
    width: 55%;
    margin: 0 5%;
    float: left;
    pointer-events: none;
    .engraving-view-message-content {
      overflow: auto;
      overflow-y: hidden;
      div {
        white-space: pre;
        word-wrap: normal;
        font-size: 18px;
        line-height: 65px;
        vertical-align: middle;
        img {
          height: auto;
          width: 45px;
          vertical-align: middle;
        }
        .monogram_custom_text {
          font-size: 20px;
          padding: 0 10%;
          display: inline-block;
          white-space: pre;
        }
      }
    }
  }
  .engraving-toggle-edit {
    display: block;
    margin-top: 10px;
  }
  .engraving-view-footer {
    float: left;
    margin: 3% 0 3% 5%;
  }
}
.birth-month-container {
  .birthday-panel {
    &__title {
      font-size: 15px;
    }
  }
  .select-birth-month {
    height: 3em;
    margin: 5px 0;
    width: 48%;
  }
  input[type='submit'] {
    &.btn {
      margin-bottom: 20px;
    }
  }
}
.offer-code {
  &__byline {
    &.game1,
    &.game2,
    &.game3,
    &.game4 {
      display: none;
    }
  }
}

.engraving_motif {
  img {
    width: 50px;
    height: auto;
  }
}
