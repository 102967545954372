.appt-book {
  #booking-step2 {
    .book-now-bar {
      #appt-book-book-submit {
        top: -1px !important;
        left: -5px !important;
      }
    }
  }
  #booking-step3 {
    .registration-last-name {
      float: left;
      margin-right: 1.4%;
    }
  }
  .my-appointments {
    .appointment-details {
      .appt-date-cal {
        .add-cal-link {
          font-size: 15px !important;
        }
      }
    }
  }
  .confirmation-container__item {
    height: 210px !important;
  }
  @media (max-width: 410px) {
    .appt-book-sections-container {
      .appt-book-section {
        .service-content__select-section {
          height: 228px;
        }
      }
    }
  }
  @media (max-width: 361px) {
    .appt-book-sections-container {
      .appt-book-section {
        .service-content__select-section {
          height: auto !important;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .appt-book-sections-container {
      .appt-book-section {
        .location-selector {
          hr {
            margin-top: 6em !important;
          }
        }
        .service-content__select-section {
          margin-top: 0px;
          height: 247px !important;
        }
        .appointment-select {
          .calendar-head {
            .cal-head-content {
              .cal-copy {
                text-align: center !important;
                &:after {
                  content: ' >' !important;
                }
              }
            }
          }
        }
        .booking-container {
          height: 70px;
        }
        .location-selector__form {
          .ui-select {
            overflow: visible;
            height: 40px;
          }
        }
      }
    }
  }
  .confirm-container {
    .error-messages {
      .error-message {
        a {
          color: $color-error;
        }
      }
    }
  }
}

.appointment-cancellation {
  .appt-book-link {
    display: inline-block;
    float: none;
    margin-bottom: 0px;
  }
}

.appt-book-overlay {
  .overlay-content-container {
    .overlay-close {
      cursor: pointer;
      text-align: right;
      display: block;
    }
  }
}
