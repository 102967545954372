.site-content {
  .offer-block-formatter {
    .site-email-signup__fields {
      input[type='email'] {
        display: block;
      }
      input[type='email']::-webkit-input-placeholder {
        font-size: 12px;
      }
      input[type='email']::-moz-placeholder {
        font-size: 12px;
      }
      input[type='email']:-ms-input-placeholder {
        font-size: 12px;
      }
    }
    .site-email-signup__terms-conditions__more {
      margin-bottom: 0;
    }
  }
}

.results__products {
  .product-grid {
    .product-grid__item {
      display: inline-table;
      .product__image {
        width: 100%;
      }
    }
  }
}

.site-email-signup__success {
  &.welcome-15 {
    &.success {
      padding: 3em 1em 1em 1em;
      .site-email-signup__success-header,
      .site-email-signup__success-offer,
      .site-email-signup__success-reminder {
        width: auto;
      }
    }
  }
  .welcome-15 & {
    padding: 2em 0.5em 0 1.5em;
    .site-email-signup__success-offer {
      font-size: 20px;
      padding-bottom: 14px;
    }
    .site-email-signup__success-terms {
      width: 245px;
    }
  }
}

.store-locator__results-container {
  .store-locator__custom_filters {
    margin-top: 15px;
    .store-locator__custom_filters-item {
      margin-bottom: 10px;
      .filter-desc {
        margin-left: 40px;
        display: block;
        font-size: 14px;
      }
    }
  }
  .store-locator__controls {
    input[type='text'] {
      width: 40%;
    }
    .search-submit {
      padding: 9px 10px 8px 10px;
      font-size: 14px;
    }
  }
  .store-locator__tooltip-product-types {
    text-transform: uppercase;
    font-size: 12px;
    .pc {
      padding: 2px 2px 0px 2px;
      &.pc {
        &--fs {
          background-color: #d39939;
        }
        &--ms {
          background-color: #8a3180;
        }
        &--am {
          background-color: #ddc63b;
        }
        &--hs {
          background-color: #2d8e58;
        }
        &--cs {
          background-color: #c93742;
        }
        &--lss {
          background-color: #3173a5;
        }
        &--sp {
          background-color: #389dcc;
        }
      }
    }
  }
}

.getthefacts {
  &__store_meta_info {
    text-align: left;
  }
}

.esearch-nav {
  &__popular {
    text-align: left;
  }
  &__popular-list {
    font-size: 1em;
    display: block;
    padding: 0.25em;
    a {
      font-size: 1em;
      display: block;
      padding: 0.25em;
    }
  }
  .search-form {
    input[type='text'] {
      &.search-form__field {
        font-size: 1em;
      }
    }
  }
}

.cs-page {
  .fieldset {
    input[name='CITY'] {
      margin-bottom: 1px;
    }
  }
}

.spp__container {
  .spp_customer_reviews {
    #pr-reviewdisplay {
      .p-w-r {
        .pr-review-snapshot {
          .pr-snippet-reco-to-friend {
            top: 105px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .cs-page {
    &__content-item {
      .content-header {
        .breadcrumbs {
          font-size: 1.6em;
        }
      }
    }
  }
}

.spp-content {
  .product-full {
    .p-w-r {
      .pr-review-snippet-container {
        float: none;
      }
    }
    .product-shade-picker {
      width: 100%;
    }
  }
}

.tout {
  &__text {
    &--center-center {
      position: absolute;
    }
  }
}

.site {
  &-header {
    &__nav {
      display: flex;
      flex-direction: column;
    }
    &__content-block-sitewide-banner {
      order: 3;
    }
  }
}
